import React, { useState, useEffect } from "react";
import {
    Box,
    SimpleGrid,
    VStack,
    Link,
    Text,
    Divider,
    Select, // Import Select from Chakra UI
    useBreakpointValue,
    ButtonGroup,
    Button,
    Stack,
    IconButton,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'; // Import icons
import SearchBar from "../components/SearchBar";
import Card from "../components/Card";
import ContentCard from "../components/ContentCard";
import VCFirmsAndPeople from "../data/VCFirmsAndPeople.json";
import BrightButton from '../components/BrightButton'; // Adjust the import path based on your file structure
// import usePageTracking from '../hooks/usePageTracking'; // Adjust the import path as needed


const HomePage = () => {
    const [tempSearchTerm, setTempSearchTerm] = useState("");
    const [filter, setFilter] = useState("all");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [filteredItems, setFilteredItems] = useState([]);
    const totalPages = Math.ceil(VCFirmsAndPeople.length / itemsPerPage);
    
    useEffect(() => {
        let filtered = VCFirmsAndPeople;

        if (filter !== "all") {
            filtered = filtered.filter(item =>
                filter === "peace"
                    ? item["Signed Letter for Peace"]?.toLowerCase() === "yes"
                    : item["Anti/Neutral"]?.toLowerCase() === filter
            );
        }

        if (tempSearchTerm) {
            filtered = filtered.filter(item =>
                item.Firm?.toLowerCase().includes(tempSearchTerm.toLowerCase()) ||
                item.Name?.toLowerCase().includes(tempSearchTerm.toLowerCase())
            );
        }

        setFilteredItems(filtered.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    }, [filter, tempSearchTerm, currentPage]);

    const handleSearch = (searchValue) => {
        setTempSearchTerm(searchValue); // If using tempSearchTerm to hold the search query before the search button is pressed
        // If you decide to directly use searchTerm, then you should call setSearchTerm here
    };

    const handleClearSearch = () => {
        setTempSearchTerm("");
        setCurrentPage(1);
    };

    const filterComponent = useBreakpointValue({
        base: "select", // 'base' is the smallest breakpoint, e.g., mobile
        md: "buttons", // 'md' and up will use buttons
    });

    return (
        <>
            <Box p={4}>

                <ContentCard marginTop="90px" width="100%">


                    <VStack align="start" spacing={4}>

                        <Text>
                            In October, VCs rushed to sign a letter of support for Israel (
                            <Link
                                href="https://docs.google.com/document/d/e/2PACX-1vTc_OIs8MSVxqz0To2diHiDd_UIQBg_wBHLnmIQNtoTRQaFrC2ZcUWiOqR7dHMe2KnfnU-Bi7n-1XpW/pub"
                                isExternal
                                color="teal.500"
                            >
                                view document
                            </Link>
                            ). Many people signed this document and took to social media with little understanding
                            of the history of the Israeli occupation of Palestine. We've seen
                            horrific statements such as individuals calling for the complete eradication
                            of Gaza. Several months later, our team has heard
                            from many VCs feeling unsettled by the disproportionate 
                            response by Israel and its bombardment on civilian populations. 
                            Individuals like Paul Graham, Founder of YCombinator,
                            and Paul Biggar, Founder of CircleCI and DarkLang, have shown
                            admirable courage in laying down their reputations for the sake of
                            truth and have suffered the consequences for it. Through their
                            example and others, we feel hopeful for a better tomorrow.
                        </Text>

                        <Text>
                            Since October, we have compiled an extensive list of VCs and VC
                            Firms that are Anti-Palestinian and VCs that have not publicly
                            taken a stance on this issue. The objective of PeaceVC.com is to
                            build a list of VC firms that desire peace and prosperity for all
                            people, starting with calling for a permanent ceasefire to end the conflict in Gaza. 
                            There is still much work to be done to identify and
                            build this collective of VCs who support ending the war in Palestine. 
                            For inquiries or if you have input you'd like to
                            add to this project, please reach out to us at:
                            <Link
                            href="info@peacevc.com"
                            isExternal
                            color="teal.500"
                            >
                            info@peacevc.com
                            </Link>
                            
                        </Text>

                        <Divider my={4} />

                        <Text>
                            For more information and other beneficial tools, please visit:
                        </Text>
                        <Link
                            href="https://techforpalestine.org"
                            isExternal
                            color="teal.500"
                        >
                            techforpalestine.org
                        </Link>
                        <Link href="https://disoccupied.com" isExternal color="teal.500">
                            disoccupied.com
                        </Link>
                        <Box padding="4" maxWidth="960px" marginX="auto">
                            {/* Add the BrightButton component to the page */}
                            <BrightButton />
                        </Box>
                        <Divider my={4} />
                        {filterComponent === "select" ? (
                            <Select placeholder="Filter VCs" value={filter} onChange={(e) => setFilter(e.target.value)} mb={4}>
                                <option value="all">All VCs</option>
                                <option value="neutral">Neutral VCs</option>
                                <option value="anti">Anti Palestinian VCs</option>
                                <option value="peace">PeaceVCs</option>
                            </Select>
                        ) : (
                            <ButtonGroup variant="outline" spacing={4} justifyContent="center" width="full" mb={4}>
                                <Button onClick={() => setFilter("all")} isActive={filter === "all"}>All VCs</Button>
                                <Button onClick={() => setFilter("neutral")} isActive={filter === "neutral"}>Neutral VCs</Button>
                                <Button onClick={() => setFilter("anti")} isActive={filter === "anti"}>Anti Palestinian VCs</Button>
                                <Button onClick={() => setFilter("peace")} isActive={filter === "peace"}>PeaceVCs</Button>
                            </ButtonGroup>
                        )}
                    </VStack>
                    {/* Pagination Controls */}
                    <Stack direction="row" spacing={4} justify="center" mt={8}>
                        <IconButton
                            icon={<ChevronLeftIcon />}
                            onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
                            isDisabled={currentPage === 1}
                            aria-label="Previous Page"
                        />
                        <Text>{`Page ${currentPage} of ${totalPages}`}</Text>
                        <IconButton
                            icon={<ChevronRightIcon />}
                            onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages))}
                            isDisabled={currentPage === totalPages}
                            aria-label="Next Page"
                        />
                    </Stack>
                    <SearchBar
                        tempSearchTerm={tempSearchTerm}
                        onTempSearchTermChange={setTempSearchTerm}
                        onSearchClick={handleSearch}
                        onClearSearch={handleClearSearch}
                    />

                    <SimpleGrid columns={{ sm: 2, md: 3, lg: 4 }} spacing={5}>
                        {filteredItems.map((item, index) => <Card key={index} item={item} />)}
                    </SimpleGrid>
                    {/* Pagination Controls */}
                    <Stack direction="row" spacing={4} justify="center" mt={8}>
                        <IconButton
                            icon={<ChevronLeftIcon />}
                            onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
                            isDisabled={currentPage === 1}
                            aria-label="Previous Page"
                        />
                        <Text>{`Page ${currentPage} of ${totalPages}`}</Text>
                        <IconButton
                            icon={<ChevronRightIcon />}
                            onClick={() => setCurrentPage(Math.min(currentPage + 1, totalPages))}
                            isDisabled={currentPage === totalPages}
                            aria-label="Next Page"
                        />
                    </Stack>


                </ContentCard>
            </Box>
        </>
    );
};

export default HomePage;
