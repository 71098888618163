import React from 'react';
import { Button, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom'; // If you're navigating within a React Router application

const BrightButton = () => {
  // Adjust the button colors based on the current color mode if you're using Chakra's Color Mode feature
  const backgroundColor = useColorModeValue('orange.400', 'orange.600');
  const hoverBgColor = useColorModeValue('orange.500', 'orange.700');

  return (
    <Button
      as={RouterLink}
      to="/support" // Change to your support page's path
      backgroundColor={backgroundColor}
      color="white"
      _hover={{ bg: hoverBgColor }}
      size="lg" // Large button size
      shadow="md" // Medium shadow for a subtle depth effect
      _active={{ transform: 'scale(0.95)' }} // Slightly scale down on click
    >
      Sign Here to Become a PeaceVC
    </Button>
  );
};

export default BrightButton;
