import React from 'react';
import {
  Flex,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Image,
  VStack,
  Link,
  DrawerHeader,
  Center, // Importing Center for centering the logo
  Box,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

const NavBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        as="nav"
        align="center"
        justify="center" // Centering content
        padding="1.5rem"
        bg="teal.500"
        color="white"
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex="banner"
        width="100%"
      >
        <Box display={{ base: 'block', md: 'none' }} onClick={onOpen}>
          <IconButton
            icon={<HamburgerIcon />}
            variant="outline"
            aria-label="Open Menu"
          />
        </Box>

        {/* Centering the logo specifically for desktop */}
        <Center width="full">
          <RouterLink to="/">
            <Image
              src={`${process.env.PUBLIC_URL}/logolarge.png`} // Adjust the path to your logo
              alt="PeaceVC Logo"
              width="200px"
            />
          </RouterLink>
        </Center>
      </Flex>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Navigation</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Link as={RouterLink} to="/" onClick={onClose}>Home</Link>
              {/* Keeping Support link only in the sidebar */}
              <Link as={RouterLink} to="/support" onClick={onClose}>Support</Link>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NavBar;
