import React from 'react';
import { Box, VStack, Text, Heading, Divider } from '@chakra-ui/react';
import ContentCard from '../components/ContentCard'; // Ensure ContentCard is ready to accept children
// import usePageTracking from '../hooks/usePageTracking'; // Adjust the import path as needed


const SupportPage = () => {
  
  return (
    <Box p={5}>
      <ContentCard title="Support Peace for All" marginTop="90px">
        <VStack align="start" spacing={4}>
          <Text>
            After six months, Israel’s war in Gaza has killed more than 30,000 Palestinians, with women and children making up two-thirds of the dead. Tens of thousands in Gaza are on the brink of starvation. 20+ hospitals have been bombed. Water, electricity, and medical supplies are almost completely depleted.
          </Text>
          <Text>
            Just a few days ago, aid workers from the World Central Kitchen were killed in airstrikes that resulted in 7 deaths and a complete pause on humanitarian aid from the WCK and other humanitarian organizations that were en route to Gaza.
          </Text>
          <Heading size="md" paddingTop="2">
            Our Call to Action
          </Heading>
          <Text>
            We are VCs and investors who are heartbroken and grieving the tragic loss of life and we condemn the loss of all innocent life. We affirm the Palestinians’ right to life, freedom, and aid. We call for an immediate and permanent ceasefire and for Israel to ensure consistent and unobstructed flow of humanitarian assistance. We call on other VCs to stand with us and sign this statement in solidarity with Palestinians and Israelis who desire a peaceful resolution to this brutal war.
          </Text>
        </VStack>
        <Divider my={4} />
        <Box
          as="iframe"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdjyVMAtse1lMmuIfd21-MHbFKG013xuRo_lEpLItBlahfYbg/viewform?embedded=true"
          width="100%" // Responsive width
          height="1200px" // Set a fixed height to avoid internal scrolling, adjust based on your form length
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          style={{ border: "none", overflow: "hidden" }} // Additional styles to remove border and hide overflow
          scrolling="no" // Disables scrolling
        >
          Loading…
        </Box>
      </ContentCard>
    </Box>
  );
};

export default SupportPage;
