import React from 'react';
import {
  Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text, Link, Heading, VStack, Button
} from '@chakra-ui/react';

const Card = ({ item }) => {
  // Function to handle the click event of the request info button
  const handleRequestInfoClick = () => {
    console.log(`Requesting more info for ${item.Firm || item.Name}`);
  };

  // Determine the background color based on the "Anti/Neutral" attribute
  const bgColor = item["Anti/Neutral"]?.toLowerCase() === "anti" ? "red.200" : 
                  item["Anti/Neutral"]?.toLowerCase() === "neutral" ? "yellow.200" : "white";

  return (
    <Accordion allowToggle w="100%">
      <AccordionItem border="1px" borderColor="gray.200" borderRadius="md" bg={bgColor}>
        <>
          <AccordionButton _expanded={{ bg: bgColor }} px={5} py={3}>
            <Box flex="1" textAlign="left">
              <Heading size="md">{item.Firm || item.Name}</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <VStack align="start" spacing={3}>
              {Object.entries(item).filter(([key, value]) => value && key !== 'Firm' && key !== 'Name').map(([key, value]) => (
                <Box key={key}>
                  <Heading size="sm" as="h4" textTransform="capitalize">{key.replace(/_/g, ' ')}:</Heading>
                  {key.toLowerCase() === 'website' ? (
                    <Link href={value.startsWith('http') ? value : `http://${value}`} isExternal color="teal.500">
                      {value}
                    </Link>
                  ) : (
                    <Text>{value}</Text>
                  )}
                </Box>
              ))}
            </VStack>
            <Button colorScheme="teal" size="sm" mt={4} onClick={handleRequestInfoClick}>
              Click here to request more info
            </Button>
          </AccordionPanel>
        </>
      </AccordionItem>
    </Accordion>
  );
};

export default Card;
