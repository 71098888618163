import React from 'react'; // React import is required for JSX
import { ChakraProvider } from '@chakra-ui/react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar'; // Correct path relative to src
import HomePage from './pages/HomePage'; // Correct path relative to src
import SupportPage from './pages/SupportPage'; // Correct path relative to src

function App() {
  return (
    <ChakraProvider>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/support" element={<SupportPage />} />
          {/* Continue adding more routes as needed */}
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
