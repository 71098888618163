import React, { useState } from 'react';
import { Input, InputGroup, InputLeftElement, HStack, Button } from '@chakra-ui/react';
import { Search2Icon } from '@chakra-ui/icons';

const SearchBar = ({ onSearchClick }) => {
  const [inputValue, setInputValue] = useState('');

  // Update to handle button click
  const handleSearchClick = () => {
    onSearchClick(inputValue); // Invoke the passed function with the current input value
  };

  return (
    <HStack my={4}>
      <InputGroup size="lg">
        <InputLeftElement pointerEvents="none">
          <Search2Icon color="gray.300" />
        </InputLeftElement>
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)} // Update inputValue as the user types
          placeholder="Search for VC Firms and Founders..."
          variant="filled"
        />
      </InputGroup>
      <Button colorScheme="teal" onClick={handleSearchClick}>Search</Button>
    </HStack>
  );
};

export default SearchBar;
