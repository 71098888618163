import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const ContentCard = ({ title, children, width, ...rest }) => (
  <Box
    p={5}
    borderWidth="1px"
    borderRadius="lg"
    overflow="hidden"
    w={width || { base: "90%", sm: "80%", md: "70%", lg: "60%", xl: "50%" }} // Use the width prop if provided, otherwise use default values
    mx="auto" // Sets the left and right margins to auto, centering the element
    my={6} // Adds some vertical spacing
    {...rest} // Spread the rest of the props
  >
    {title && <Text fontSize="xl" fontWeight="bold" mb={4}>{title}</Text>}
    {children}
  </Box>
);

export default ContentCard;
